import React, { useEffect, useRef } from 'react';
import { useAppSelector, useAppDispatch } from 'app/config/store';
import ItemsListHeader from '../components/ItemsListHeader';
import ItemsListBody from '../components/ItemsListBody';
import { getEntity, selectFilteredEntities, setEntity } from 'app/entities/xtraho_services/form/form.reducer';

function ItemsList() {
  const dispatch = useAppDispatch();
  let init = useRef(false);
  const filteredEntities: any = useAppSelector(state => selectFilteredEntities(state.xtrahoui.form));
  const statusSelection = useAppSelector(state => state.xtrahoui.form.selectedStatus);

  useEffect(() => {
    if (filteredEntities.length && !init.current) {
      dispatch(getEntity(filteredEntities[0].id));
      init.current = true;
    }
  });

  useEffect(() => {
    if (filteredEntities.length) {
      dispatch(getEntity(filteredEntities[0].id));
    } else {
      dispatch(setEntity({}));
    }
  }, [statusSelection]);

  return (
    <div className="items-list">
      <ItemsListHeader number={filteredEntities.length} />
      <ItemsListBody selectedForms={filteredEntities} />
    </div>
  );
}

export default ItemsList;
