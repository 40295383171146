import React from 'react';
import Gauge from 'app/entities/xtraho_services/components/Gauge';
import { getEntity } from 'app/entities/xtraho_services/form/form.reducer';
import { useAppDispatch } from 'app/config/store';

function FormItem(props) {
  const dispatch = useAppDispatch();
  const handleClick = () => {
    actionSetSelectedEntity();
  };

  const actionSetSelectedEntity = () => {
    dispatch(getEntity(props.id));
  };

  return (
    <button className={`item ${props.active ? 'active' : ''}`} onClick={handleClick}>
      <Gauge percentage={props.percentage} />
      <span className="item__detail">
        <span className="item__info">
          <span className="item__date">{props.date}</span>
          <span className="item__separator">•</span>
          <span className="item__status">{props.status}</span>
        </span>
        <span className="item__id" title={props.id}>
          #{props.id}
        </span>
      </span>
      <span className="item__arrow">
        <span className="material-symbols-outlined">east</span>
      </span>
    </button>
  );
}

export default FormItem;
