import axios from 'axios';
import { createAsyncThunk, createSelector, isFulfilled, isPending } from '@reduxjs/toolkit';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { createEntitySlice, EntityState, IQueryParams, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { defaultValue, IForm } from 'app/shared/model/xtraho_services/form.model';
import { IRootState } from 'app/config/store';
import { entitiesTypes } from 'app/config/fields-config';

const initialState: EntityState<IForm> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  selectedStatus: 'all',
  currentPage: 0,
  numberOfItems: 10,
  formType: entitiesTypes[0].name,
};

let fetchedServicesName = null;
const fetchServicesName = async () => {
  try {
    const response = await axios.get('api/servicesName');
    if (typeof response.data === 'string' && response.data.trim().length > 0) {
      fetchedServicesName = response.data;
      console.log('fetchedServicesName: ', fetchedServicesName);
    } else {
      console.log('Response is not a non-empty string');
    }
  } catch (error) {
    console.error('Error fetching the data:', error);
  }
};
fetchServicesName().then();

let config = null;
const fetchConfig = async () => {
  try {
    const response = await axios.get('api/kv/fields.json');
    config = response.data;
    console.log('config: ', config);
  } catch (error) {
    console.error('Error fetching the config:', error);
  }
};
fetchConfig().then();

const apiUrl = () => {
  const xtrahoServicesName = fetchedServicesName ?? 'xtraho-services';
  return `services/${xtrahoServicesName}/api/forms`;
};

// Selectors
const entities = state => state.entities;
const status = state => state.selectedStatus;
export const selectFilteredEntities = createSelector([entities, status], (e, s) => {
  return e.filter((entity: { status: string }) => (s === 'all' ? true : entity.status.toLowerCase() === s));
});

// Actions

export const getEntities = createAsyncThunk(
  'form/fetch_entity_list',
  async ({ page, size, sort }: IQueryParams) => {
    const requestUrl = `${apiUrl()}?${sort ? `page=${page}&size=${size}&sort=${sort}&` : ''}cacheBuster=${new Date().getTime()}`;
    return axios.get<IForm[]>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const getOpenEntities = createAsyncThunk(
  'form/open',
  async ({ page, size, sort }: IQueryParams) => {
    const requestUrl = `${apiUrl()}/open?${sort ? `page=${page}&size=${size}&sort=${sort}&` : ''}cacheBuster=${new Date().getTime()}`;
    return axios.get<IForm[]>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const getNextOpenEntities = createAsyncThunk(
  'form/new',
  async ({ page, size, sort }: IQueryParams) => {
    const requestUrl = `${apiUrl()}/open?${sort ? `page=${page}&size=${size}&sort=${sort}&` : ''}cacheBuster=${new Date().getTime()}`;
    return axios.get<IForm[]>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const getEntity = createAsyncThunk(
  'form/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl()}/${id}`;
    return axios.get<IForm>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const createEntity = createAsyncThunk(
  'form/create_entity',
  async (entity: IForm, thunkAPI) => {
    const result = await axios.post<IForm>(apiUrl(), cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const updateEntity = createAsyncThunk(
  'form/update_entity',
  async (entity: IForm, thunkAPI) => {
    const result = await axios.put<IForm>(`${apiUrl()}/${entity.id}`, cleanEntity(entity));
    const state = thunkAPI.getState() as IRootState;
    const itemsNumber = state.xtrahoui.form.entities.length;
    thunkAPI.dispatch(
      getOpenEntities({
        page: 0,
        size: itemsNumber,
        sort: `id,desc`,
      }),
    );
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const skipEntity = createAsyncThunk(
  'form/skip_entity',
  async (entity: IForm, thunkAPI) => {
    const result = await axios.patch<IForm>(`${apiUrl()}/${entity.id}`, cleanEntity(entity));
    const state = thunkAPI.getState() as IRootState;
    const itemsNumber = state.xtrahoui.form.entities.length;
    thunkAPI.dispatch(
      getOpenEntities({
        page: 0,
        size: itemsNumber,
        sort: `id,desc`,
      }),
    );
    const stateXtrahoui = state.xtrahoui.form;
    const entityIndex = selectFilteredEntities(stateXtrahoui).findIndex(el => el.id === entity.id);
    const nextEntity =
      entityIndex >= 0 && entityIndex < selectFilteredEntities(stateXtrahoui).length - 1
        ? selectFilteredEntities(stateXtrahoui)[entityIndex + 1].id
        : null;
    nextEntity ? thunkAPI.dispatch(getEntity(nextEntity)) : thunkAPI.dispatch(setEntity({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const partialUpdateEntity = createAsyncThunk(
  'form/partial_update_entity',
  async (entity: IForm, thunkAPI) => {
    const result = await axios.patch<IForm>(`${apiUrl()}/${entity.id}`, cleanEntity(entity));
    const state = thunkAPI.getState() as IRootState;
    const itemsNumber = state.xtrahoui.form.entities.length;
    thunkAPI.dispatch(
      getOpenEntities({
        page: 0,
        size: itemsNumber,
        sort: `id,desc`,
      }),
    );
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const deleteEntity = createAsyncThunk(
  'form/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl()}/${id}`;
    const result = await axios.delete<IForm>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const uploadEntity = createAsyncThunk(
  'form/upload_entity',
  async (formData: FormData, thunkAPI) => {
    return await axios.post(`${apiUrl()}/upload`, formData);
  },
  { serializeError: serializeAxiosError },
);

// slice

export const FormSlice = createEntitySlice({
  name: 'form',
  initialState,
  reducers: {
    setSelectStatus(state, action) {
      state.selectedStatus = action.payload;
    },
    setEntity(state, action) {
      state.entity = action.payload;
    },
    nextPage(state) {
      state.currentPage++;
    },
    setFormType(state, action) {
      state.formType = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities, getOpenEntities), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(getNextOpenEntities), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: [...state.entities, ...data],
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(skipEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
      })
      .addMatcher(isPending(getEntities, getEntity, getOpenEntities, getNextOpenEntities), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(skipEntity, createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset, setSelectStatus, setEntity, nextPage, setFormType } = FormSlice.actions;

// Reducer
export default FormSlice.reducer;
