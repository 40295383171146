import React, { useId, useRef, useState } from 'react';
import { useAppDispatch } from 'app/config/store';

import InputWrapper from './InputWrapper';
import { errorToastDisplay } from 'app/shared/util/entity-utils';
import { partialUpdateEntity } from 'app/entities/xtraho_services/form/form.reducer';
import { translate } from 'react-jhipster';
import NoInput from 'app/entities/xtraho_services/components/NoInput';
import StatusDisplay from 'app/entities/xtraho_services/components/StatusDisplay';

const InputCheckbox = props => {
  const dispatch = useAppDispatch();
  const ref = useRef<HTMLDivElement>(null);
  const componentId = useId();
  const [success, setSuccess] = useState(null);

  const handleInput = e => {
    const entity = {
      id: props.formEntity.id,
      status: 'WIP',
      extractedData: {},
    };

    entity.extractedData[props.model_id] = JSON.parse(JSON.stringify(props.formEntity.extractedData[props.model_id]));

    if (!entity.extractedData[props.model_id]) {
      return;
    }

    e.target.value
      ? (entity.extractedData[props.model_id].value = 'selected')
      : (entity.extractedData[props.model_id].value = 'unselected');
    entity.extractedData[props.model_id].confidenceScore = -1;

    dispatch(partialUpdateEntity(entity))
      .unwrap()
      .then(() => {
        setSuccess(true);
        setTimeout(() => {
          setSuccess(null);
        }, 2000);
      })
      .catch(error => {
        setSuccess(false);
        setTimeout(() => {
          setSuccess(null);
        }, 2000);
        errorToastDisplay(translate('formProblem') + ' ' + error);
      });
  };

  if (!props.formEntity.extractedData[props.model_id]) {
    return <NoInput {...props} />;
  }

  return (
    <InputWrapper {...props} ref={ref}>
      <StatusDisplay success={success} />
      <div className="form-check">
        <input
          onChange={e => handleInput(e)}
          className="form-check-input"
          type="checkbox"
          key={props.formEntity.id + componentId}
          name={props.formEntity.extractedData[props.model_id].name}
          id={props.formEntity.extractedData[props.model_id].name}
          data-parent={props.formEntity.extractedData[props.model_id].name}
          defaultChecked={props.formEntity.extractedData[props.model_id].value === 'selected'}
        />
        <label className="form-check-label" htmlFor={props.formEntity.extractedData[props.model_id].name}>
          {props.input_label}
        </label>
      </div>
    </InputWrapper>
  );
};

export default InputCheckbox;
