import React, { useId, useRef, useState } from 'react';
import { translate } from 'react-jhipster';
import Gauge from 'app/entities/xtraho_services/components/Gauge';
import { errorToastDisplay, percentageCalculation } from 'app/shared/util/entity-utils';
import { useAppDispatch } from 'app/config/store';
import InputWrapper from 'app/entities/xtraho_services/components/InputWrapper';
import { partialUpdateEntity } from 'app/entities/xtraho_services/form/form.reducer';
import NoInput from 'app/entities/xtraho_services/components/NoInput';
import StatusDisplay from 'app/entities/xtraho_services/components/StatusDisplay';

const InputArray = props => {
  const ref = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const componentId = useId();
  const [success, setSuccess] = useState(null);

  // Recursive function to find a field in all the extractedData
  const findFieldRecursively = (data, fieldName) => {
    for (const key in data) {
      if (key === fieldName && data[key].hasOwnProperty('value')) {
        return data[key];
      } else if (typeof data[key] === 'object' && data[key] !== null) {
        const result = findFieldRecursively(data[key], fieldName);
        if (result) return result;
      }
    }
    return null;
  };

  const handleInput = e => {
    const entity = {
      id: props.formEntity.id,
      status: 'WIP',
      extractedData: {},
    };

    entity.extractedData[e.target.dataset.parent] = JSON.parse(JSON.stringify(props.formEntity.extractedData[e.target.dataset.parent]));

    entity.extractedData[e.target.dataset.parent].confidenceScore = -1;

    const field = findFieldRecursively(entity.extractedData, e.target.name);

    if (!field || field.value === e.target.value) {
      return;
    }

    field.value = e.target.value;
    if (field.hasOwnProperty('confidence')) {
      field.confidence = -1;
    }

    dispatch(partialUpdateEntity(entity))
      .unwrap()
      .then(() => {
        setSuccess(true);
        setTimeout(() => {
          setSuccess(null);
        }, 2000);
      })
      .catch(error => {
        setSuccess(false);
        setTimeout(() => {
          setSuccess(null);
        }, 2000);
        errorToastDisplay(translate('formProblem') + ' ' + error);
      });
  };

  if (!props.formEntity.extractedData[props.model_id]) {
    return <NoInput {...props} />;
  }

  if (!props.formEntity.extractedData[props.model_id].value || props.formEntity.extractedData[props.model_id].value.length === 0) {
    return <p>Aucun élément trouvé</p>;
  }
  return (
    <InputWrapper {...props} ref={ref}>
      <>
        <StatusDisplay success={success} /> // TODO Improve save notification to target each input
        {props.formEntity.extractedData[props.model_id].value.map((v: any, index: string) => (
          <div key={index} className={'item-input item-input--child'}>
            <div key={v + '_' + index} className="item-input__body">
              <div className="item-input__index">Entry {index + 1}</div>
              {Object.keys(v.value).map(key => (
                <div key={key} className="item-input__element">
                  <div className="item-input__label">
                    <Gauge percentage={percentageCalculation(v.value[key].confidence)} />
                    <label className="form-label" htmlFor={key}>
                      {key}
                    </label>
                  </div>
                  <input
                    onBlur={e => handleInput(e)}
                    className="form-control"
                    type="text"
                    data-parent={props.model_id}
                    key={props.formEntity.id + componentId}
                    name={key}
                    defaultValue={v.value[key].value}
                    id={key}
                  />
                </div>
              ))}
            </div>
          </div>
        ))}
      </>
    </InputWrapper>
  );
};

export default InputArray;
