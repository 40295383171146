import React from 'react';
import Gauge from 'app/entities/xtraho_services/components/Gauge';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { skipEntity, updateEntity } from 'app/entities/xtraho_services/form/form.reducer';
import { dateFormatter, errorToastDisplay, percentageCalculation, successToastDisplay, dateInput } from 'app/shared/util/entity-utils';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Translate, translate } from 'react-jhipster';
import { LOCALE_FORMAT } from 'app/config/constants';
import PdfViewer from './PdfViewer';
import { fieldsConfig } from 'app/config/fields-config';
import InputText from 'app/entities/xtraho_services/components/InputText';
import InputTextarea from 'app/entities/xtraho_services/components/InputTextarea';
import InputCheckbox from 'app/entities/xtraho_services/components/InputCheckbox';
import InputDate from 'app/entities/xtraho_services/components/InputDate';
import InputArray from 'app/entities/xtraho_services/components/InputArray';
import InputRadio from 'app/entities/xtraho_services/components/InputRadio';

const locale = LOCALE_FORMAT;

function FormDetail() {
  const dispatch = useAppDispatch();

  const formEntity = useAppSelector(state => state.xtrahoui.form.entity);
  const globalConfidenceScore = useAppSelector(state => state.xtrahoui.form.entity.confidenceScore);

  const InputMap = {
    text: InputText,
    tel: InputText,
    email: InputText,
    date: InputDate,
    textarea: InputTextarea,
    checkbox: InputCheckbox,
    radio: InputRadio,
    table: InputArray,
  };

  /**
   * Change formEntity status on skip
   */
  const skip = () => {
    const entity = {
      id: formEntity.id,
      status: 'WIP',
    };

    dispatch(skipEntity(entity))
      .unwrap()
      .then(() => {
        successToastDisplay(translate('formSkipped'));
      })
      .catch(error => {
        errorToastDisplay(translate('formProblem') + ' ' + error);
      });
  };

  /**
   * Save the new value of a field when modified
   */

  const onSubmit = () => {
    const entity = JSON.parse(JSON.stringify(formEntity));
    let errorFields = [];
    for (const [key, val] of Object.entries(formEntity.extractedData) as any) {
      if (entity.extractedData[key].error) {
        errorFields.push(entity.extractedData[key].error);
      }
    }
    if (errorFields.length > 0) {
      errorToastDisplay('Unable to send the data. Please save correct values first.');
      return;
    }
    // TODO Call the API when available
    entity.status = 'PROCESSED';
    dispatch(updateEntity(entity))
      .unwrap()
      .then(() => {
        successToastDisplay(translate('formSent'));
      })
      .catch(error => {
        errorToastDisplay(translate('formProblem'));
      });
  };

  return (
    <div className="item-detail">
      <ToastContainer />
      {Object.keys(formEntity).length ? (
        <>
          <div className="item-detail__datas">
            <div className="item-detail__datas__wrapper">
              <div className="item-detail-header">
                <div className="item-detail-header__info">
                  <Gauge percentage={percentageCalculation(globalConfidenceScore)} size={'big'} />
                  <div className="item-detail-header__info__wrapper">
                    <div className="item-detail-header__data">
                      <span className="item-detail-header__date">{dateFormatter(formEntity.createdDate, locale)}</span>
                      <span className="item-detail-header__separator"> • </span>
                      <span className="item-detail-header__status">{formEntity.status}</span>
                    </div>
                    <h2>#{formEntity.id}</h2>
                  </div>
                </div>
                <div className="item-detail-header__actions">
                  <div className="item-detail-header__actions-secondary">
                    <button className="btn btn--second btn--small" onClick={onSubmit}>
                      <Translate contentKey="entity.action.send">Send</Translate>{' '}
                      <span className="icon material-symbols-outlined">verified</span>
                    </button>
                    <button className="btn btn--transparent btn--small" onClick={skip}>
                      <Translate contentKey="entity.action.skip">Skip</Translate>{' '}
                      <span className="icon material-symbols-outlined"> keyboard_tab</span>
                    </button>
                  </div>
                </div>
              </div>
              <form>
                <div className="item-detail-body">
                  {fieldsConfig.map((field: any, index) => {
                    const DynamicInput = InputMap[field.type];
                    return DynamicInput != null ? <DynamicInput key={index} {...field} formEntity={formEntity} /> : null;
                  })}
                </div>
              </form>
            </div>
          </div>
          <div className="item-detail__overview">
            <div className="item-detail__overview__wrapper">
              {formEntity.formImageContentType.startsWith('image/') ? (
                <div>
                  <img src={`data:${formEntity.formImageContentType};base64,${formEntity.formImage}`} alt="Document preview" />
                </div>
              ) : null}
              {formEntity.formImageContentType == 'application/pdf' ? <PdfViewer base64String={formEntity.formImage} /> : null}
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
}

export default FormDetail;
