import React from 'react';
import { translate } from 'react-jhipster';

const StatusDisplay = props => {
  const status = props.success === true ? 'success' : props.success === false ? 'error' : 'null';
  return (
    <div className={'input-notification input-notification--' + status}>
      <span className="status status--success">{translate('savedSuccess')}</span>
      <span className="status status--error">{translate('savedError')}</span>
    </div>
  );
};

export default StatusDisplay;
