import React from 'react';
import Gauge from 'app/entities/xtraho_services/components/Gauge';
import { percentageCalculation } from 'app/shared/util/entity-utils';
import { translate } from 'react-jhipster';

const InputWrapper = React.forwardRef<
  HTMLDivElement,
  {
    formEntity: any;
    model_id: string;
    front_label: string;
    children: any;
  }
>((props, ref) => {
  return (
    <div className={'item-input' + (props.formEntity.extractedData[props.model_id].error !== null ? ' item-input--error' : '')} ref={ref}>
      <div className="item-input__label">
        <Gauge percentage={percentageCalculation(props.formEntity.extractedData[props.model_id].confidenceScore)} />
        <span className="form-label">
          {props.front_label} <span className="saved__label saved__label--success">{translate('savedSuccess')}</span>{' '}
          <span className="saved__label saved__label--error">{translate('savedError')}</span>
        </span>
      </div>
      <div className="item-input__body">
        <div className="item-input__element">{props.children}</div>
        {props.formEntity.extractedData[props.model_id].error && (
          <div className={`item-input__notice show`}>{props.formEntity.extractedData[props.model_id].error.message}</div>
        )}
      </div>
    </div>
  );
});

export default InputWrapper;
