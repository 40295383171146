import pick from 'lodash/pick';
import { IPaginationBaseState, ISortBaseState } from 'react-jhipster';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

/**
 * Removes fields with an 'id' field that equals ''.
 * This function was created to prevent entities to be sent to
 * the server with an empty id and thus resulting in a 500.
 *
 * @param entity Object to clean.
 */
export const cleanEntity = entity => {
  const keysToKeep = Object.keys(entity).filter(k => !(entity[k] instanceof Object) || (entity[k]['id'] !== '' && entity[k]['id'] !== -1));

  return pick(entity, keysToKeep);
};

/**
 * Simply map a list of element to a list a object with the element as id.
 *
 * @param idList Elements to map.
 * @returns The list of objects with mapped ids.
 */
export const mapIdList = (idList: ReadonlyArray<any>) => idList.filter((id: any) => id !== '').map((id: any) => ({ id }));

export const overrideSortStateWithQueryParams = (paginationBaseState: ISortBaseState, locationSearch: string) => {
  const params = new URLSearchParams(locationSearch);
  const sort = params.get('sort');
  if (sort) {
    const sortSplit = sort.split(',');
    paginationBaseState.sort = sortSplit[0];
    paginationBaseState.order = sortSplit[1];
  }
  return paginationBaseState;
};

export const overridePaginationStateWithQueryParams = (paginationBaseState: IPaginationBaseState, locationSearch: string) => {
  const sortedPaginationState: IPaginationBaseState = <IPaginationBaseState>(
    overrideSortStateWithQueryParams(paginationBaseState, locationSearch)
  );
  const params = new URLSearchParams(locationSearch);
  const page = params.get('page');
  if (page) {
    sortedPaginationState.activePage = +page;
  }
  return sortedPaginationState;
};

export const percentageCalculation: any = (number: number) => {
  return Math.trunc(number * 100);
};

export const successToastDisplay: any = (message: string) => {
  return toast.success(message);
};

export const errorToastDisplay: any = (message: string) => {
  return toast.error(message);
};

export const dateFormatter: any = (date: Date, locale: string) => {
  return new Date(date).toLocaleDateString(locale);
};

/**
 * Format a date field to be displayed
 *
 * @param datevalue Value of a date (object or string)
 */
export const dateInput = (datevalue: any) => {
  //If the date has never been modified, the format is [AAAA,M,J]
  //If not, please refer to the model's setting
  if (typeof datevalue == 'object') {
    let newdate = [];
    for (const element in datevalue) {
      newdate.push(datevalue[element]);
    }
    let element = '';
    let formattedDate = [];
    for (const elem in newdate) {
      element = newdate[elem].toString();
      if (element.length < 2) {
        element = '0' + element;
      }
      formattedDate.push(element);
    }
    return formattedDate.join('-');
  } else {
    //If the date has been modified earlier, the format is already correct
    return datevalue;
  }
};

/**
 * Recursive function to find a field in all the extractedData
 * @param data
 * @param fieldName
 */
export const findFieldRecursively = (data, fieldName) => {
  for (const key in data) {
    if (key === fieldName && data[key].hasOwnProperty('value')) {
      return data[key];
    } else if (typeof data[key] === 'object' && data[key] !== null) {
      const result = findFieldRecursively(data[key], fieldName);
      if (result) return result;
    }
  }
  return null;
};
