// Sample config file
// To use it, you need to remove the .sample and adapt the configuration to the client needs

export const entitiesTypes = [
  {
    name: 'indexes',
  },
];

export const statusList = [
  {
    label: 'Pending',
    value: 'analyzed',
  },
  {
    label: 'Work in progress',
    value: 'wip',
  },
];

export const fieldsConfig = [
  {
    model_id: '01_commentaire_envoi',
    front_label: 'Commentaire envoi',
    type: 'textarea',
  },
  {
    model_id: '02_id',
    front_label: 'ID',
    type: 'text',
  },
  {
    model_id: '03_coach_sectoriel',
    front_label: 'Coach sectoriel',
    type: 'text',
  },
  {
    model_id: '04_fonds_sectoriel',
    front_label: 'Fonds sectoriel',
    type: 'text',
  },
  {
    model_id: '05_operateur_formation',
    front_label: 'Opérateur de formation',
    type: 'text',
  },
  {
    model_id: '06_soussigne',
    front_label: 'Soussigné',
    type: 'text',
  },
  {
    model_id: '07_operateur_formation_2',
    front_label: 'Opérateur de formation',
    type: 'text',
  },
  {
    model_id: '08_fonds_sectoriel_2',
    front_label: 'Fonds sectoriel',
    type: 'text',
  },
  {
    model_id: '09_nom_chef_entreprise',
    front_label: "Nom du chef d'entreprise / directeur",
    type: 'text',
  },
  {
    model_id: '10_prenom_chef_entreprise',
    front_label: "Prénom du chef d'entreprise / directeur",
    type: 'text',
  },
  {
    model_id: '11_denomination_entreprise',
    front_label: "Dénomination de l'entreprise",
    type: 'text',
  },
  {
    model_id: '12_adresse_unite',
    front_label: "Adresse de l'unité",
    type: 'textarea',
  },
  {
    model_id: '13_telephone',
    front_label: 'Téléphone',
    type: 'tel',
  },
  {
    model_id: '14_fax',
    front_label: 'Fax',
    type: 'tel',
  },
  {
    model_id: '15_email',
    front_label: 'E-mail',
    type: 'email',
  },
  {
    model_id: '16_commission_paritaire',
    front_label: 'Commission paritaire',
    type: 'text',
  },
  {
    model_id: '17_numero_onss',
    front_label: 'Numéro ONSS',
    type: 'text',
  },
  {
    model_id: '18_numero_bce',
    front_label: 'Numéro BCE',
    type: 'text',
  },
  {
    model_id: '19_numero_ue',
    front_label: 'Numéro UE',
    type: 'text',
  },
  {
    model_id: '20_seppt',
    front_label: 'Nom du service externe/interne de prévention SEPPT',
    type: 'text',
  },
  {
    model_id: '21_metier',
    front_label: 'Métier',
    type: 'text',
  },
  {
    model_id: '22_avis_favorable',
    front_label: 'Avis favorable du coach sectoriel',
    type: 'checkbox',
    input_label: 'Oui',
  },
  {
    model_id: '23_tuteur_tableau',
    front_label:
      "Les tuteurs désignés remplissant les conditions de tuteur au sens de l'accord de coopération cadre du 24 octobre 2008 est / sont :",
    type: 'table',
  },
  {
    model_id: '24_lieu',
    front_label: 'Fait à :',
    type: 'text',
  },
  {
    model_id: '25_date',
    front_label: 'Date',
    type: 'date',
  },
  {
    model_ids: ['26_signature_coach', '27_signature_coordonnateur'],
    front_label: 'Signature',
    type: 'radio',
    input_labels: ['Signature du coach sectoriel ou du représentant sectoriel', 'Signature du coordonnateur, directeur ou référent'],
  },
  {
    model_id: '54_engagement_autre_apprenant',
    front_label: "Souhait ou possibilité d'engagement d'un autre apprenant",
    type: 'checkbox',
    input_label: 'Oui',
  },
  {
    model_id: '55_reglement',
    front_label: "Réglement de travail remis à l'apprenant",
    type: 'checkbox',
    input_label: 'Oui',
  },
  {
    model_id: '63_formation_outils',
    front_label: "Outils, machines et matières premières mis à disposition de l'apprenant suffisants et adaptés à l'apprentissage",
    type: 'checkbox',
    input_label: 'Oui',
  },
  {
    model_id: '64_formation_interne',
    front_label: "Formation complétée par une formation interne ou externe supplémentaire à charge de l'employeur",
    type: 'checkbox',
    input_label: 'Oui',
  },
];
