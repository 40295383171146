import React, { useId, useState } from 'react';
import { useAppDispatch } from 'app/config/store';
import { errorToastDisplay } from 'app/shared/util/entity-utils';
import { partialUpdateEntity } from 'app/entities/xtraho_services/form/form.reducer';
import { translate } from 'react-jhipster';
import NoInput from 'app/entities/xtraho_services/components/NoInput';
import StatusDisplay from 'app/entities/xtraho_services/components/StatusDisplay';

const InputRadio = props => {
  const dispatch = useAppDispatch();
  const componentId = useId();
  const [success, setSuccess] = useState(null);

  const handleInput = e => {
    const entity = {
      id: props.formEntity.id,
      status: 'WIP',
      extractedData: {},
    };

    props.model_ids.map(id => {
      entity.extractedData[id] = JSON.parse(JSON.stringify(props.formEntity.extractedData[id]));
      e.target.value === id ? (entity.extractedData[id].value = 'selected') : (entity.extractedData[id].value = 'unselected');
      entity.extractedData[id].confidenceScore = -1;
    });

    dispatch(partialUpdateEntity(entity))
      .unwrap()
      .then(() => {
        setSuccess(true);
        setTimeout(() => {
          setSuccess(null);
        }, 2000);
      })
      .catch(error => {
        setSuccess(false);
        setTimeout(() => {
          setSuccess(null);
        }, 2000);
        errorToastDisplay(translate('formProblem') + ' ' + error);
      });
  };

  return (
    <div className="item-input">
      <StatusDisplay success={success} />
      <div className="item-input__label">
        <span className="form-label">{props.front_label}</span>
      </div>
      <div className="item-input__body">
        {props.model_ids.map((id, index) =>
          props.formEntity.extractedData[id] ? (
            <div key={index} className="item-input__element form-check">
              <input
                className="form-check-input"
                type="radio"
                key={props.formEntity.id + componentId}
                name={props.model_ids.join('-')}
                value={id}
                id={props.formEntity.extractedData[id].name}
                data-parent={props.formEntity.extractedData[id].name}
                defaultChecked={props.formEntity.extractedData[id].value === 'selected'}
                onClick={e => handleInput(e)}
              />
              <label className="form-check-label" htmlFor={id}>
                {props.input_labels[index]}
              </label>
            </div>
          ) : (
            <NoInput model_id={id} />
          ),
        )}
      </div>
    </div>
  );
};

export default InputRadio;
