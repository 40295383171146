import React, { useId, useRef } from 'react';
import { useAppDispatch } from 'app/config/store';
import InputWrapper from './InputWrapper';
import { errorToastDisplay } from 'app/shared/util/entity-utils';
import { partialUpdateEntity } from 'app/entities/xtraho_services/form/form.reducer';
import { translate } from 'react-jhipster';

const NoInput = props => {
  return (
    <p>
      No extracted data found for input ID : <b>{props.model_id}</b>
    </p>
  );
};

export default NoInput;
